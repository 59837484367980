@use "src/mixins";

.PolitikaKonfidentsialnosti {
  padding: 0 var(--horizontal-padding);
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 38px;
    line-height: 40px;
    font-weight: 300;
    margin: 0;

    @include mixins.narrowerThan905() {
      font-size: 34px;
    }
  }

  a {
    color: currentColor;
  }
}
