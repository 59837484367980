@use "src/mixins";

.Footer {
  padding: 60px var(--horizontal-padding);
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: auto;
  background-color: #e5eaf1;
  box-sizing: border-box;

  @include mixins.narrowerThan600() {
    padding: 16px var(--horizontal-padding);
  }

  .topPart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 43px;

    @include mixins.narrowerThan1440() {
      gap: 52px;
    }

    @include mixins.narrowerThan905() {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      row-gap: 10px;
    }

    @include mixins.narrowerThan600() {
      gap: 32px;
      grid-template-columns: 73px auto;
      picture,
      picture img {
        width: 100%;
      }
    }

    .CondensedLogo {
      grid-row: 1 / span 2;

      @include mixins.narrowerThan905() {
        grid-row: 1 / span 1;
        grid-column: 1 / span 2;
      }
    }

    a.chatbot {
      @include mixins.narrowerThan1240() {
        width: 225px;
        height: 125px;
      }

      @include mixins.narrowerThan905() {
        width: 100%;
        height: unset;
        font-size: 14px;
      }

      @include mixins.narrowerThan600() {
        font-size: 20px;
      }
    }

    .buttonsWrapper {
      display: grid;
      gap: 16px;

      @include mixins.narrowerThan1240() {
        width: 225px;
      }

      @include mixins.narrowerThan905() {
        width: 100%;
      }

      @include mixins.narrowerThan600() {
        grid-column: 2;
        grid-row: 3;
      }
    }

    .buttonsContainer {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      grid-row: 2;
      grid-column: 2;
      gap: 10px;

      @include mixins.narrowerThan905() {
        grid-row: 1 / span 1;
      }

      @include mixins.narrowerThan600() {
        // grid-row: 2 / span 2;
        // grid-column: 1 / span 2;
        display: contents;
        .heading {
          grid-row: 2;
        }
        .SmButtons {
          grid-row: 3;
          ul {
            flex-wrap: wrap;
            gap: 8px !important;
            li,
            li a,
            li a svg {
              font-size: 32px;
              line-height: 32px;
              height: 32px;
              width: 32px;
            }
          }
        }
      }

      .heading {
        height: 27px;
        width: 165px;
        color: #04103080;
        font-family: "e-Ukraine";
        font-size: 18px;
        font-weight: 300;
        height: 27px;
        letter-spacing: 0;
        line-height: 27px;
        white-space: nowrap;
      }

      .SmButtons {
        width: auto;
        ul {
          gap: 16px;
          li {
            svg {
              font-size: 36px;
              width: 36px;
              height: 36px;

              --sm-color-foreground: #041030;
              --sm-color-background: #e5eaf1;
            }
          }
        }
      }
    }
  }

  .bottomPart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    color: #04103080;

    @include mixins.narrowerThan600() {
      flex-direction: column;
      align-items: flex-start;
    }

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .left {
      font-size: 16px;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
      .hostUrl {
        font-size: 20px;
      }
      .copyright {
        text-wrap: nowrap;
        @include mixins.narrowerThan905() {
          font-size: 14px;
        }
      }
    }
  }
}
